/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #0c076b;
$danger: #e63c2f;
$secondary: #3e4145;
$warning: #f8b92e;

.theme-navbar {
    background-color: #0c076b;
    color: #fff;

    .nav-link {
        color: #fff !important;
    }

    .nav-item {
        border-bottom: solid 3px #b3b5b7;
    }

    .nav-item:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #b3b5b7;
        border-radius: 2px;
    }
}

.theme-footer {
    background-color: #3e4145;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #cecece
    }
}

.brand-logo {
    height: auto;
    width: 170px;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"